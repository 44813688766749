export function registerServiceWorker () {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js')
        .then(registration => {
          // console.log('Service Worker registered successfully:', registration)

          // Check for updates
          registration.addEventListener('updatefound', () => {
            const newWorker = registration?.installing
            if (!newWorker) return
            newWorker.addEventListener('statechange', () => {
              if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
                // Automatically post the SKIP_WAITING message and refresh the page
                newWorker.postMessage({ type: 'SKIP_WAITING' })
                // window.location.href = '/welcome?newVersion=1'
              }
            })
          })
        })
        .catch(error => {
          console.error('Service Worker registration failed:', error)
        })
    })

    // Handle updates when the user navigates to the page
    let refreshing = false
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (!refreshing) {
        // window.location.href = '/welcome?newVersion=1'
        refreshing = true
      }
    })
  } else {
    console.log('Service workers are not supported in this browser.')
  }
}
