import { useState, useCallback } from 'react'
import {
  Card,
  Text,
  Autocomplete,
  BlockStack,
  Icon,
  Button,
  InlineError,
  IndexTable,
  EmptySearchResult
} from '@shopify/polaris'
import { SearchIcon, XSmallIcon } from '@shopify/polaris-icons'
import { useTranslation } from 'react-i18next'
import { EditableCell } from '@shared/components/EditableCell'
import { PRODUCTION_MAX_PRODUCT_LENGTH } from '@bom/utils/constants'
import { trimProductName } from '@bom/utils'

export const ProductCard = ({
  bom,
  showRequiredError,
  onSelectOutputProduct,
  onRemoveOutputProduct,
  onQuantityChange,
  isReadonly
}) => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState('')

  const handleInputChange = useCallback((value) => {
    setInputValue(value)
    if (value.length > 0) {
      onSelectOutputProduct(value)
      setInputValue('')
    }
  }, [onSelectOutputProduct])

  const emptyStateMarkup = <EmptySearchResult />
  const outputProduct = bom?.output_product
  const bomId = bom?.bom_id

  return (
    <Card>
      <BlockStack gap='400'>
        <Text as="h2" variant="headingMd">
          {t('product_card.title')}
        </Text>
        {!outputProduct && (
          <BlockStack gap='100'>
            <Autocomplete
              id="outputProductSearch"
              options={[]}
              selected={[]}
              onSelect={() => {}}
              textField={
                <Autocomplete.TextField
                  onChange={handleInputChange}
                  value={inputValue}
                  placeholder={t('product_card.search_for_products')}
                  autoComplete="off"
                  prefix={<Icon source={SearchIcon} />}
                />
              }
            />
            {!!showRequiredError && (
              <InlineError
                message={t('product_card.product_required')}
                fieldID="outputProductSearch"
              />
            )}
          </BlockStack>
        )}
        {outputProduct && (
          <IndexTable
            itemCount={1}
            emptyState={emptyStateMarkup}
            headings={[
              { title: t('product_card.product') },
              {},
              { title: t('product_card.quantity') },
              {}
            ]}
            selectable={false}
          >
            <IndexTable.Row
              id={bomId}
              key={bomId}
            >
              <IndexTable.Cell colSpan={2}>
                <BlockStack gap="100">
                  <Text variant="bodyMd" fontWeight="bold">
                    {trimProductName(outputProduct.product_name, PRODUCTION_MAX_PRODUCT_LENGTH)}
                  </Text>
                  {outputProduct.product_variant_name && (
                    <Text variant="bodySm" color="subdued">
                      {outputProduct.product_variant_name}
                    </Text>
                  )}
                </BlockStack>
              </IndexTable.Cell>
              <IndexTable.Cell>
                {isReadonly
                  ? (
                    <Text variant="bodySm" color="subdued">
                      {Math.trunc(parseInt(bom?.output_quantity))}
                    </Text>
                    )
                  : (
                    <>
                      <EditableCell
                        fieldID={`quantity_${bomId}`}
                        initialValue={bom?.output_quantity}
                        onSave={onQuantityChange}
                        minValue="1"
                      />
                      {(isNaN(bom?.output_quantity)) && (
                      <InlineError
                        message={t('product_card.quantity_required')}
                        fieldID={`quantity_${bomId}`}
                        />
                      )}
                    </>
                    )
              }
              </IndexTable.Cell>
              <IndexTable.Cell>
                {!isReadonly && (
                  <Button
                    onClick={() => onRemoveOutputProduct()}
                    plain
                    icon={XSmallIcon}
                    variant="tertiary"
                    accessibilityLabel={t('product_card.remove_material', { name: outputProduct.product_name })}
                  />
                )}
              </IndexTable.Cell>
            </IndexTable.Row>
          </IndexTable>
        )}
      </BlockStack>
    </Card>
  )
}
