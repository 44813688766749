import React, { useState, useCallback, useEffect } from 'react'
import {
  Modal,
  ResourceList,
  ResourceItem,
  TextField,
  Icon,
  Text,
  BlockStack,
  InlineError
} from '@shopify/polaris'
import { SearchIcon } from '@shopify/polaris-icons'
import { useTranslation } from 'react-i18next'

export const ProductSelectionModal = ({
  open,
  onClose,
  onSave,
  products,
  title,
  allowMultiple,
  initialSelectedProducts = [],
  initialSearchValue = '',
  limit = 20,
  forbiddenProductIds = [],
  forbiddenUsedProductIds = [],
  onUpdateForbiddenIds = () => {},
  doFilterOnlyFinishedProduct = false
}) => {
  const { t } = useTranslation()
  const [selectedProducts, setSelectedProducts] = useState(initialSelectedProducts)
  const [searchValue, setSearchValue] = useState(initialSearchValue)
  const [filteredProducts, setFilteredProducts] = useState(products)
  const [hasNextPage, setHasNextPage] = useState(limit < products.length)
  const [hasPreviousPage, setHasPreviousPage] = useState(false)
  const [totalProducts, setTotalProducts] = useState(products.length)
  const [currentPageProducts, setCurrentPageProducts] = useState(products.slice(0, limit))
  const [offset, setOffset] = useState(0)
  const [finishedProductsIds, setFinishedProductsIds] = useState(null)

  useEffect(() => {
    if (!doFilterOnlyFinishedProduct) return
    setFinishedProductsIds(products.filter((product) => product?.inventory_type === 'finished_product').map((product) => product.product_id))
  }, [doFilterOnlyFinishedProduct, products])

  const filterProducts = (products, searchValue) => {
    const productNameMatches = products.filter((product) =>
      product.product_name?.toLowerCase().includes(searchValue.toLowerCase())
    )

    const variantMatches = products.filter((product) =>
      !product.product_name?.toLowerCase().includes(searchValue.toLowerCase()) &&
      product.product_variant_name?.toLowerCase().includes(searchValue.toLowerCase())
    )

    return [...productNameMatches, ...variantMatches]
  }

  useEffect(() => {
    if (!open) return
    setSelectedProducts(initialSelectedProducts)
    setSearchValue(initialSearchValue)
    const newFilteredProducts = filterProducts(products, initialSearchValue)
    setCurrentPageProducts(newFilteredProducts.slice(0, limit))
  }, [open, initialSearchValue, initialSelectedProducts, products, limit])

  const handleSearchChange = useCallback((value) => {
    setSearchValue(value)
    const newFilteredProducts = filterProducts(products, value)
    setFilteredProducts(newFilteredProducts)
    setCurrentPageProducts(newFilteredProducts.slice(0, limit))
    setHasNextPage(limit < newFilteredProducts.length)
    setHasPreviousPage(false)
    setTotalProducts(newFilteredProducts.length)
  }, [products, limit])

  const handleSelectionChange = useCallback((selectedIds) => {
    const newSelectedProducts = products
      .filter((product) =>
        selectedIds.includes(product.product_id)
      )

    if (!allowMultiple) {
      onSave(newSelectedProducts)
      onClose()
    }

    setSelectedProducts(newSelectedProducts)
  }, [products, allowMultiple, onSave, onClose])

  const handleSave = useCallback(() => {
    const newProducts = selectedProducts
      .filter((product) =>
        !initialSelectedProducts.some(
          (initialProduct) => initialProduct.product_id === product.product_id
        )
      )

    onSave(newProducts)
    onClose()
  }, [onSave, selectedProducts, onClose, initialSelectedProducts])

  const handleItemClick = useCallback((item, id) => {
    setSelectedProducts([item, ...selectedProducts])

    if (!allowMultiple) {
      setSelectedProducts([item])
      onSave([item])
      onUpdateForbiddenIds([item.product_id])
      onClose()
    }
  }, [allowMultiple, onSave, onClose, selectedProducts, onUpdateForbiddenIds])

  const handleNextPage = useCallback(() => {
    const newOffset = Math.min(offset + limit, totalProducts)

    setOffset(newOffset)
    setHasNextPage(newOffset + limit < totalProducts)
    setHasPreviousPage(newOffset > 0)
    setCurrentPageProducts(filteredProducts.slice(newOffset, newOffset + limit))
  }, [offset, limit, totalProducts, filteredProducts])

  const handlePreviousPage = useCallback(() => {
    const newOffset = Math.max(0, offset - limit)

    setOffset(newOffset)
    setHasNextPage(newOffset + limit < totalProducts)
    setHasPreviousPage(newOffset > 0)
    setCurrentPageProducts(filteredProducts.slice(newOffset, newOffset + limit))
  }, [offset, limit, totalProducts, filteredProducts])

  const handleOnClose = useCallback(() => {
    setOffset(0)
    setCurrentPageProducts(products.slice(0, limit))
    setFilteredProducts(products)
    setHasPreviousPage(false)
    setTotalProducts(products.length)
    setHasNextPage(limit < products.length)
    onClose()
  }, [products, limit, onClose])

  const isForbiddenProduct = (productId) => forbiddenProductIds.includes(productId)
  const isAlreadyUsedProduct = (productId) => forbiddenUsedProductIds.includes(productId)
  const isFinishedProduct = (productId) => finishedProductsIds.includes(productId)
  const isFinishedProductError = (productId) => doFilterOnlyFinishedProduct && !isFinishedProduct(productId)
  const isError = (productId) => isForbiddenProduct(productId) || isAlreadyUsedProduct(productId) || isFinishedProductError(productId)
  const getProductErrorMessage = (productId, allowMultiple) => {
    if (isAlreadyUsedProduct(productId)) {
      return t('bundles.product_already_used')
    }

    if (isForbiddenProduct(productId)) {
      return allowMultiple
        ? t('bundles.material_already_selected')
        : t('bundles.product_already_selected')
    }

    if (isFinishedProductError) {
      return t('productions.finished_product_only')
    }

    return null
  }

  return (
    <Modal
      open={open}
      onClose={handleOnClose}
      title={title}
      primaryAction={{
        content: t('bundles.add'),
        onAction: handleSave
      }}
      secondaryActions={[
        {
          content: t('bundles.cancel'),
          onAction: onClose
        }
      ]}
    >
      <Modal.Section>
        <BlockStack gap='100'>
          <TextField
            value={searchValue}
            onChange={handleSearchChange}
            prefix={<Icon source={SearchIcon} />}
            placeholder={t('bundles.search_for_products')}
            autoComplete="off"
            autoFocus
          />
          <ResourceList
            resourceName={{ singular: t('bundles.product'), plural: t('bundles.products') }}
            items={currentPageProducts}
            renderItem={(item) => {
              const isInitiallySelected = initialSelectedProducts.some(
                (selectedProduct) => selectedProduct.product_id === item.product_id
              )
              const productId = item.product_id
              const productName = item.product_name
              const variantName = item.product_variant_name
              return (
                <ResourceItem
                  key={productId}
                  id={productId}
                  onClick={(id) => handleItemClick(item, id)}
                  accessibilityLabel={t('bundles.select_product', { name: productName })}
                  disabled={isInitiallySelected || isError(productId)}
                >
                  <Text tone={isError(productId) ? 'disabled' : 'base'} variant="bodyMd" fontWeight="bold" as="h3">
                    {productName}
                  </Text>
                  <Text tone={isError(productId) ? 'disabled' : 'base'}>
                    {variantName}
                  </Text>
                  { isError(productId) &&
                    <InlineError
                      message={getProductErrorMessage(productId, allowMultiple)}
                      fieldID="myFieldID"
                    />
                  }
                </ResourceItem>
              )
            }}
            selectedItems={selectedProducts.map(p => p.product_id)}
            onSelectionChange={handleSelectionChange}
            selectable={allowMultiple}
            pagination={{
              hasNext: hasNextPage,
              onNext: handleNextPage,
              hasPrevious: hasPreviousPage,
              onPrevious: handlePreviousPage
            }}
          />
        </BlockStack>
      </Modal.Section>
    </Modal>
  )
}
