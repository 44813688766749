exports.DEFAULT_BOM_STATUS = 'active'
exports.JOB_STATUS = {
  FAILED: 'failed',
  SUCCEEDED: 'succeeded',
  IN_PROGRESS: 'in_progress'
}
exports.FREE_PLAN_LIMIT = 3
exports.FREE_PLAN_PRODUCTION_LIMIT = 10
exports.DEFAULT_INVENTORY_TYPE = 'bundled_product'
exports.COMPONENT_PRODUCT_MAX_LENGTH = 60
exports.OUTPUT_PRODUCT_MAX_LENGTH = 30
exports.PRODUCTION_MAX_PRODUCT_LENGTH = 20
