import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DataUpdateContext } from '@shared/context/context'
import { ExportIcon, ImportIcon } from '@shopify/polaris-icons'
import { importBoms } from '@src/shared/services/bomService'
import { formatToCsv, parseCsvToObjects } from '@src/shared/services/csvService'
import { fetchProductVariants } from '@src/shared/services/productsService'
import {
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import {
  Page,
  Box,
  Button,
  ButtonGroup,
  Banner,
  Card,
  Text,
  List,
  Link,
  SkeletonPage,
  Layout,
  SkeletonDisplayText,
  BlockStack,
  SkeletonBodyText
} from '@shopify/polaris'
import { getProductsUrl } from '@src/shared/utils'

export const BundleImportView = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { companyId, appBridge, subscriptionPlan, shopName } = useContext(DataUpdateContext)
  const [isLoadingExportProductVariantIdsButton, setIsLoadingExportProductVariantIdsButton] = useState(false)
  const [isLoadingExportSampleFileButton, setIsLoadingExportSampleFileButton] = useState(false)
  const [importExportStatus, setImportExportStatus] = useState({
    showBanner: false,
    title: null,
    tone: null
  })
  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const [isLoadingImportButton, setIsLoadingImportButton] = useState(false)
  const loadingPage = (
    <SkeletonPage primaryAction>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <SkeletonDisplayText size="large" />
            <BlockStack gap='200'>
              <SkeletonBodyText />
              <SkeletonBodyText />
              <SkeletonBodyText />
              <SkeletonBodyText />
              <SkeletonBodyText />
            </BlockStack>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  )

  useEffect(() => {
    if (subscriptionPlan === 'free') navigate('/bundles')
    if (subscriptionPlan) {
      setIsLoadingPage(false)
    }
  }, [subscriptionPlan, navigate])

  const handleBackClick = useCallback(() => {
    navigate('/bundles')
  }, [navigate])

  const createBlob = (csvData, fileName) => {
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }

  const handleImport = async () => {
    setImportExportStatus({ showBanner: false, ...importExportStatus })

    try {
      const input = document.createElement('input')

      input.type = 'file'
      input.accept = '.csv'

      const filePromise = new Promise((resolve, reject) => {
        input.onchange = (event) => {
          const file = event.target.files[0]
          const reader = new FileReader()

          reader.onload = (e) => {
            try {
              setIsLoadingImportButton(true)

              const csvData = e.target.result

              if (!csvData || csvData.trim() === '') {
                throw new Error(t('bundles.banner.import.errors.upload_file_no_data'))
              }

              const parsedObjects = parseCsvToObjects(csvData)

              if (!Array.isArray(parsedObjects) || parsedObjects.length === 0) {
                throw new Error(t('bundles.banner.import.errors.invalid_parsed_file'))
              }

              resolve(parsedObjects)
            } catch (error) {
              reject(error)
            }
          }

          reader.readAsText(file)
        }
      })

      input.click()

      const parsedObjects = await filePromise

      const response = await importBoms(parsedObjects, companyId, appBridge)

      if (!response.total) {
        throw new Error(t('bundles.banner.import.errors.backend_error'))
      }

      setImportExportStatus({
        showBanner: true,
        title: response.total + t('bundles.banner.import.success'),
        tone: 'success'
      })

      const redirectAfterImportTimeoutMs = 1500

      setTimeout(() => {
        navigate('/bundles')
      }, redirectAfterImportTimeoutMs)
    } catch (error) {
      console.error('Import Failed:', error.message)
      setImportExportStatus({
        showBanner: true,
        title: t('bundles.banner.import.failure'),
        tone: 'critical'
      })
    } finally {
      setIsLoadingImportButton(false)
    }
  }

  const handleExportProductVariantIds = async () => {
    setImportExportStatus({ showBanner: false, ...importExportStatus })
    setIsLoadingExportProductVariantIdsButton(true)

    try {
      const response = await fetchProductVariants(companyId, appBridge)
      const productsWithRenamedFields = response.products.map(product => ({
        product_title: product?.product_name,
        variant_title: product?.product_variant_name,
        variant_id: parseInt(product?.external_variant_id, 10)
      }))
      const csvData = formatToCsv(productsWithRenamedFields)
      createBlob(csvData, t('bundles.csv_exported_variants_filename') + '.csv')

      setImportExportStatus({
        showBanner: true,
        title: response.total + t('bundles.banner.export_products_and_variants.success'),
        tone: 'success'
      })
    } catch (error) {
      console.error('Export Product Variant IDs Failed:', error.message)

      setImportExportStatus({
        showBanner: true,
        title: t('bundles.banner.export_products_and_variants.failure'),
        tone: 'critical'
      })
    } finally {
      setIsLoadingExportProductVariantIdsButton(false)
    }
  }

  const handleExportSampleFile = () => {
    setImportExportStatus({ showBanner: false, ...importExportStatus })
    setIsLoadingExportSampleFileButton(true)
    const sampleSize = 10

    try {
      const sampleData = Array(sampleSize).fill().map(() => ({
        bundle_variant_id: '',
        component_variant_id: '',
        quantity: 1
      }))
      const csvData = formatToCsv(sampleData)
      createBlob(csvData, t('bundles.csv_exported_sample_file_filename') + '.csv')

      setImportExportStatus({
        showBanner: true,
        title: t('bundles.banner.export_sample_file.success'),
        tone: 'success'
      })
    } catch (error) {
      console.error('Export Sample File Failed:', error.message)

      setImportExportStatus({
        showBanner: true,
        title: t('bundles.banner.export_sample_file.failure'),
        tone: 'critical'
      })
    } finally {
      setIsLoadingExportSampleFileButton(false)
    }
  }

  const content = (
    <Page
      title={t('bundles.import_bundles')}
      primaryAction={
        <Button
          icon={ImportIcon}
          variant='primary'
          onClick={() => handleImport()}
          loading={isLoadingImportButton}
        >
          {t('bundles.import')}
        </Button>
      }
      secondaryActions={
        <Box paddingInlineEnd='200'>
          <ButtonGroup>
            <Button
              icon={ExportIcon}
              variant='monochromePlain'
              onClick={() => handleExportProductVariantIds()}
              loading={isLoadingExportProductVariantIdsButton}
            >
              {t('bundles.export_product_variant_ids')}
            </Button>
            <Button
              icon={ExportIcon}
              variant='monochromePlain'
              onClick={() => handleExportSampleFile()}
              loading={isLoadingExportSampleFileButton}
            >
              {t('bundles.export_sample_file')}
            </Button>
          </ButtonGroup>
        </Box>
      }
      backAction={{
        content: t('bundles.back'),
        onAction: handleBackClick
      }}
      divider
      >
      { importExportStatus.showBanner &&
        (
        <Box paddingBlockEnd='300'>
          <Banner
            title={importExportStatus.title}
            tone={importExportStatus.tone}
            onDismiss={() => setImportExportStatus({ ...importExportStatus, showBanner: false })}
          />
        </Box>
        )
      }
      <Card>
        <Box>
          <Text as="h2" variant="headingSm">
            {t('bundles.bundle_import_view.heading')}
          </Text>
          <Box paddingBlockStart='200'>
            <List type="number">
              <List.Item>
                {getProductsUrl(shopName)
                  ? (
                    <Link url={getProductsUrl(shopName)} target="_blank">
                      {t('bundles.bundle_import_view.steps.1.title')}
                    </Link>
                    )
                  : (
                    <Text>{t('bundles.bundle_import_view.steps.1.title')}</Text>
                    )
                }
              </List.Item>
              <List.Item>
                <Link onClick={() => handleExportProductVariantIds()}>
                  {t('bundles.bundle_import_view.steps.2.title')}
                </Link>
                <Text as='div'>
                  {t('bundles.bundle_import_view.steps.2.steps.heading')}
                  <List type='bullet'>
                    <List.Item>
                      {t('bundles.bundle_import_view.steps.2.steps.1.title')}
                    </List.Item>
                    <List.Item>
                      {t('bundles.bundle_import_view.steps.2.steps.2.title')}
                    </List.Item>
                  </List>
                </Text>
              </List.Item>
              <List.Item>
                <Link onClick={() => handleExportSampleFile()}>
                  {t('bundles.bundle_import_view.steps.3.title')}
                </Link>
                <Text as='div'>
                  {t('bundles.bundle_import_view.steps.3.steps.heading')}
                  <List type='bullet'>
                    <List.Item>
                      <Text as='span' fontWeight='bold'>
                        {t('bundles.bundle_import_view.steps.3.steps.1.title')}
                      </Text>
                      &nbsp;
                      <Text as='span'>
                        {t('bundles.bundle_import_view.steps.3.steps.1.description')}
                      </Text>
                    </List.Item>
                    <List.Item>
                      <Text as='span' fontWeight='bold'>
                        {t('bundles.bundle_import_view.steps.3.steps.2.title')}
                      </Text>
                      &nbsp;
                      <Text as='span'>
                        {t('bundles.bundle_import_view.steps.3.steps.2.description')}
                      </Text>
                    </List.Item>
                    <List.Item>
                      <Text as='span' fontWeight='bold'>
                        {t('bundles.bundle_import_view.steps.3.steps.3.title')}
                      </Text>
                      &nbsp;
                      <Text as='span'>
                        {t('bundles.bundle_import_view.steps.3.steps.3.description')}
                      </Text>
                    </List.Item>
                  </List>
                  <Text as='div'>
                    {t('bundles.bundle_import_view.steps.3.additional_info')}
                  </Text>
                </Text>
              </List.Item>
              <List.Item>
                <Link onClick={() => handleImport()}>
                  {t('bundles.bundle_import_view.steps.4.title')}
                </Link>
                <Text as='div'>
                  {t('bundles.bundle_import_view.steps.4.description')}
                </Text>
              </List.Item>
            </List>
          </Box>
        </Box>
      </Card>
    </Page>
  )

  return isLoadingPage ? loadingPage : content
}
