import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { axiosWithCredentials } from '@shared/utils/axiosInstance'
import { currencyFormatter, dateTimeFormatter } from '@shared/utils/formatter'
import { getFormattedDates, setDefaultFilterSelectedValues } from '@shared/utils'
import { DataUpdateContext } from '@shared/context/context'
import '@shared/styles/items.css'
import { AgGridTable } from '@standalone/components/common/AGGridTable'
import { ExportButton } from '@standalone/components/agGridParams/buttons/ExportButton'
import { ResetButton } from '@standalone/components/agGridParams/buttons/ResetButton'
import { GroupingSelector } from '@standalone/components/filters/GroupingSelector'
import { YearSelector } from '@standalone/components/filters/YearSelector'
import { LocationSelector } from '@standalone/components/filters/LocationSelector'
import { SoldSale } from '@standalone/components/filters/SoldSale'

const ReportingView = () => {
  const { t, i18n } = useTranslation()
  const [products, setProducts] = useState([])
  const [locations, setLocations] = useState([])
  const [selectedLocation, setSelectedLocation] = useState('')
  const [selectedFilter, setSelectedFilter] = useState('salesHistoryUnitsData')
  const [selectedYear, setSelectedYear] = useState('2024')
  const [error, setError] = useState(null)
  const [weekColumns, setWeekColumns] = useState([])
  const [selectedGrouping, setSelectedGrouping] = useState('monthly')
  const { lastUpdate, companyId } = useContext(DataUpdateContext)
  const gridApi = useRef(null)

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel'
        }
      ],
      defaultToolPanel: ''
    }
  }, [])

  useEffect(() => {
    axiosWithCredentials
      .get(`/obius/locations?company_id=${companyId}`)
      .then((response) => {
        if (!response.data) {
          throw new Error('Failed to fetch locations')
        }
        setLocations([{ location_name: t('product.all_locations'), location_id: '' }, ...response.data.locations])
      })
      .catch((error) => {
        console.error('Error fetching locations:', error)
      })
  }, [t, companyId])

  useEffect(() => {
    const { startDate, endDate } = getFormattedDates({ selectedYear, selectedGrouping: 'monthly' })
    const params = new URLSearchParams({
      fields: 'collection_name,product_name,product_variant_name,product_sku,product_supplier,is_inventory_tracked,product_status',
      populate: 'product,collection',
      pivotColumns: 'period_label',
      pivotValue: 'metric_value',
      metric_name: `${selectedGrouping}_${selectedFilter === 'salesHistoryUnitsData' ? 'sold_quantity' : 'sales'}`,
      company_id: companyId
    })
    if (selectedLocation) {
      params.append('location_id', selectedLocation)
    } else {
      params.append('!location_id', '')
    }

    params.append('period_start_date>', startDate)
    const paramString = `${params.toString()}&period_start_date<${endDate}`

    axiosWithCredentials
      .get(`/obius/metrics?${paramString}`)
      .then((response) => {
        if (!response.data) {
          throw new Error('Failed to fetch data')
        }
        const { metrics, pivotColumns } = response.data
        if (!metrics) {
          setProducts([])
          return
        }
        setWeekColumns(pivotColumns)
        setProducts(metrics)
        setError(null)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        setError(error)
      })
  }, [selectedLocation, selectedFilter, selectedYear, selectedGrouping, companyId])
  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value)
  }

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value)
  }

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value)
  }
  const handleGroupingChange = (event) => {
    setSelectedGrouping(event.target.value)
  }
  const onGridReady = (params) => {
    gridApi.current = params.api
  }
  const generateWeekColumns = useCallback(() => {
    return weekColumns.map(week => ({
      headerName: week,
      filter: 'agNumberColumnFilter',
      valueGetter: p => p.data && +p.data[week],
      filterParams: {
        defaultOption: 'greaterThan'
      },
      aggFunc: 'sum',
      width: 133,
      valueFormatter: ({ value }) => {
        const nValue = parseFloat(value)
        if (selectedFilter === 'salesHistoryGrossData') {
          return isNaN(nValue) ? 0 : currencyFormatter.format(nValue)
        } else {
          return isNaN(nValue) ? 0 : Math.trunc(nValue)
        }
      }
    }))
  }, [weekColumns, selectedFilter])

  const onFirstDataRendered = async () => {
    await setDefaultFilterSelectedValues({ filterName: 'is_inventory_tracked', unselectedValues: ['false'], gridApi })
    await setDefaultFilterSelectedValues({ filterName: 'product_status', unselectedValues: ['archived', 'draft'], gridApi })
  }

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'collection_name',
        filter: 'agSetColumnFilter',
        pinned: 'left',
        rowGroup: true,
        hide: true,
        enableRowGroup: true,
        valueFormatter: ({ value }) => t(`${value}`)
      },
      {
        headerName: t('product.name'),
        cellStyle: { textAlign: 'left' },
        field: 'product_name',
        filter: 'agSetColumnFilter'
      },
      {
        headerName: t('product.variant'),
        field: 'product_variant_name',
        filter: 'agSetColumnFilter'
      },
      {
        headerName: t('product.sku'),
        field: 'product_sku',
        filter: 'agSetColumnFilter'
      },
      {
        headerName: t('product.supplier'),
        field: 'product_supplier',
        filter: 'agSetColumnFilter'
      },
      {
        headerName: t('product.is_inventory_tracked'),
        valueGetter: p => p.data && p.data.is_inventory_tracked === 't',
        colId: 'is_inventory_tracked',
        cellDataType: 'boolean',
        filter: 'agSetColumnFilter',
        hide: true
      },
      {
        headerName: t('product.product_status'),
        field: 'product_status',
        filter: 'agSetColumnFilter',
        hide: true
      }
    ]

    const dynamicWeekColumns = generateWeekColumns()
    return [...baseColumns, ...dynamicWeekColumns]
  }, [generateWeekColumns, t])

  return (
    <div className='dashboard-container'>
      <div className='dashboard-section'>
        <div className='dashboard-title dashboard-dropdown'>
          <div>
            <h1>{t('sidebar.reporting')}</h1>
            <h2 className='subtitle is-6 mt-1'>
              {t('common.updated')} {dateTimeFormatter(lastUpdate, i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language || 'en')}
            </h2>
          </div>
          <div className='filters-container'>
            <div className='select-container'>
              <LocationSelector
                handleLocationChange={handleLocationChange}
                selectedLocation={selectedLocation}
                locations={locations}
              />
              <span className='select-divider' />
              <SoldSale
                t={t}
                handleFilterChange={handleFilterChange}
                selectedFilter={selectedFilter}
              />
              <span className='select-divider' />
              <GroupingSelector
                handleGroupingChange={handleGroupingChange}
                selectedGrouping={selectedGrouping} t={t}
              />
              <span className='select-divider' />
              <YearSelector
                handleYearChange={handleYearChange}
                selectedYear={selectedYear}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='exports-container'>
        <div className='button-items-section'>
          <div className='button-container'>
            <ExportButton gridRef={gridApi} />
          </div>
          <div>
            <ResetButton gridRef={gridApi} />
          </div>
        </div>
      </div>
      <div className='items-section obius-grid-size-m'>
        {error && error.response && error.response.status === 404
          ? (<p>{t('error.no_data')}</p>)
          : (
              products.length === 0
                ? (<p>{t('error.no_data')}</p>)
                : (<AgGridTable
                    rowData={products}
                    colDefs={columns}
                    grandTotalRow='top'
                    onGridReady={onGridReady}
                    sideBar={sideBar}
                    onFirstDataRendered={onFirstDataRendered}
                   />)
            )}
      </div>
    </div>
  )
}

export default ReportingView
