import { createContext, useEffect, useState, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { backendRequest } from '@shared/utils/axiosInstance'
import { createMenu } from '@shared/utils/shopify'
import createApp from '@shopify/app-bridge'
export const DataUpdateContext = createContext()

export const DataUpdateProvider = ({ children }) => {
  const [lastUpdate, setLastUpdate] = useState(null)
  const [steps, setSteps] = useState(null)
  const [jobStatus, setJobStatus] = useState(null)
  const [companyId, setCompanyId] = useState(null)
  const [isAppBridgeReady, setIsAppBridgeReady] = useState(false)
  const [companyParams, setCompanyParams] = useState(null)
  const [appBridge, setAppBridge] = useState(null)
  const [isLogin, setIsLogin] = useState(null)
  const [isReadyToFetch, setIsReadyToFetch] = useState(false)
  const [landingUrl, setLandingUrl] = useState(null)
  const [companyStatus, setCompanyStatus] = useState('')
  const [shopName, setShopName] = useState('')
  const [subscriptionPlan, setSubscriptionPlan] = useState(null)
  const queryParams = useMemo(() => new URLSearchParams(window.location.search), [])
  const embedded = queryParams.get('embedded')
  const host = queryParams.get('host')
  const { t, i18n } = useTranslation()

  const getLandingUrl = ({ companyStatus, subscriptionStatus, subscriptionPlan }) => {
    switch (process.env.REACT_APP_SHOPIFY_APP) {
      case 'bom':
      case 'replen':
        return '/welcome'
      default:
        if (subscriptionPlan !== 'premium') return '/plan-selection'
        return '/welcome'
    }
  }

  useEffect(() => {
    if (embedded && isLogin && isAppBridgeReady) setIsReadyToFetch(true)
    if (!embedded && isLogin) setIsReadyToFetch(true)
  }, [isLogin, isAppBridgeReady, embedded])

  useEffect(() => {
    if (embedded === '1') {
      const fetchLocale = async ({ appBridge }) => {
        const appState = await appBridge.getState()
        const userLanguage = appState?.staffMember?.locale || 'en-US'
        if (i18n.language !== userLanguage) i18n.changeLanguage(userLanguage)
      }
      if (!host) {
        console.error('Host must be provided for embedded mode.')
        return
      }
      const appBridgeInitial = createApp({
        apiKey: process.env.REACT_APP_SHOPIFY_API_KEY,
        host,
        embedded
      })
      setAppBridge(appBridgeInitial)
      fetchLocale({ appBridge: appBridgeInitial })
      createMenu({ appBridge: appBridgeInitial, t })
      setIsAppBridgeReady(true)
    } else {
      setIsAppBridgeReady(true)
      setIsReadyToFetch(true)
    }
  }, [embedded, host, t, i18n])

  useEffect(() => {
    const login = async () => {
      if ((!embedded || !appBridge || !queryParams) && process.env.NODE_ENV !== 'development') return
      try {
        await backendRequest({ method: 'POST', url: '/auth/shopify-login', appBridge, data: { queryString: queryParams.toString() } })
        setIsLogin(true)
      } catch (error) {
        setIsLogin(false)
        console.error('Error loging in', error)
      }
    }
    login()
  }, [embedded, appBridge, queryParams])

  useEffect(() => {
    const handleJobStatusForReplen = async ({ myCompany }) => {
      const lastUpdate = myCompany.last_update
      const companyId = myCompany.company_id
      if (lastUpdate) {
        setLastUpdate(lastUpdate)
      } else {
        const interval = setInterval(async () => {
          const { data: { jobs: [job] } } = await backendRequest({
            method: 'get',
            url: `/job/jobs?company_id=${companyId}&job_type=initial_end_to_end,end_to_end&limit=1`,
            appBridge
          })
          if (!job) return
          setSteps(job.steps)
          if (job.status === 'succeeded') {
            setLastUpdate(job.start_date)
            clearInterval(interval)
          }
          if (job.status === 'failed' && job.job_type === 'initial_end_to_end') {
            setJobStatus(job.status)
            clearInterval(interval)
          }
        }, 5000)
      }
    }

    const handleJobStatusForBom = async ({ myCompany }) => {
      const companyId = myCompany.company_id
      const interval = setInterval(async () => {
        const { data: { jobs: [job] } } = await backendRequest({
          method: 'get',
          url: `/job/jobs?company_id=${companyId}&job_type=initial_product&limit=1`,
          appBridge
        })
        if (!job) return setJobStatus('failed')
        if (job.status === 'in_progress') {
          setJobStatus(job.status)
          setSteps(job.steps)
        } else {
          setJobStatus(job.status)
          clearInterval(interval)
        }
      }, 5000)
    }

    const fetchMyCompany = async () => {
      if (!isReadyToFetch) return
      try {
        const { data: myCompany } = await backendRequest({ method: 'GET', url: '/obius/companies/my-company', appBridge })

        const companyStatus = myCompany.company_status
        setCompanyStatus(companyStatus)

        const shopName = myCompany.shop_name
        setShopName(shopName)

        const companyId = myCompany.company_id
        const subscriptionStatus = myCompany.subscription_status
        const subscriptionPlan = process.env.REACT_APP_SHOPIFY_APP === 'standalone' ? 'premium' : myCompany.subscription_plan || 'free'
        const landingUrl = getLandingUrl({ companyStatus, subscriptionStatus, subscriptionPlan })
        setLandingUrl(landingUrl)
        setSubscriptionPlan(subscriptionPlan)
        setCompanyParams(myCompany.params || {})
        setCompanyId(companyId)

        switch (process.env.REACT_APP_SHOPIFY_APP) {
          case 'replen':
            await handleJobStatusForReplen({ myCompany })
            break
          case 'bom':
            await handleJobStatusForBom({ myCompany })
            break
          default:
            await handleJobStatusForReplen({ myCompany })
        }
      } catch (error) {
        console.error('Error fetching last succeeded job', error)
      }
    }
    fetchMyCompany()
  }, [appBridge, isReadyToFetch])

  const updateSubscriptionPlan = useCallback((plan) => {
    setSubscriptionPlan(plan)
  }, [setSubscriptionPlan])

  const setThresholdsRequestHeaders = useCallback((companyParams) => {
    const headers = {}
    if (companyParams.dailyVelocityThreshold !== undefined && companyParams.dailyVelocityThreshold !== null) headers['X-Daily-Velocity-Threshold'] = companyParams.dailyVelocityThreshold.toString()
    if (companyParams.replenishNowDayThreshold !== undefined && companyParams.replenishNowDayThreshold !== null) headers['X-Replenish-Now-Day-Threshold'] = companyParams.replenishNowDayThreshold.toString()
    if (companyParams.replenishSoonDayThreshold !== undefined && companyParams.replenishSoonDayThreshold !== null) headers['X-Replenish-Soon-Day-Threshold'] = companyParams.replenishSoonDayThreshold.toString()
    if (companyParams.deadstockDayThreshold !== undefined && companyParams.deadstockDayThreshold !== null) headers['X-Deadstock-Day-Threshold'] = companyParams.deadstockDayThreshold.toString()
    if (companyParams.overstockDayThreshold !== undefined && companyParams.overstockDayThreshold !== null) headers['X-Overstock-Day-Threshold'] = companyParams.overstockDayThreshold.toString()
    return headers
  }, [])

  const contextValue = useMemo(() => ({
    lastUpdate,
    companyId,
    isLogin,
    companyStatus,
    shopName,
    appBridge,
    landingUrl,
    subscriptionPlan,
    steps,
    jobStatus,
    updateSubscriptionPlan,
    setThresholdsRequestHeaders,
    companyParams,
    isAppBridgeReady,
    isReadyToFetch
  }), [
    lastUpdate,
    companyId,
    isLogin,
    companyStatus,
    shopName,
    appBridge,
    landingUrl,
    subscriptionPlan,
    steps,
    jobStatus,
    updateSubscriptionPlan,
    setThresholdsRequestHeaders,
    companyParams,
    isAppBridgeReady,
    isReadyToFetch
  ])

  return (
    <DataUpdateContext.Provider value={contextValue}>
      {children}
    </DataUpdateContext.Provider>
  )
}
