import { useState, useCallback, useEffect, useContext } from 'react'
import { DataUpdateContext } from '@shared/context/context'
import {
  fetchBomDetails,
  updateBom,
  patchBom,
  deleteBom,
  fetchBomsList
} from '@shared/services/bomService'
import { fetchProducts } from '@shared/services/productsService'
import { objectToId } from '@shared/utils'
import {
  DEFAULT_BOM_STATUS,
  DEFAULT_INVENTORY_TYPE,
  JOB_STATUS,
  FREE_PLAN_LIMIT
} from '@bom/utils/constants'
import { LARGE_REQUEST_LIMIT } from '@shared/utils/constant'
import { concatArrayWithoutDupes } from '@bom/utils'

export const useBomDetails = (
  initialBomId,
  setIsSaving,
  setForbiddenMaterialIds,
  forbiddenProductIds,
  setForbiddenProductIds,
  setUsedMaterialIds,
  setUsedProductIds,
  setShowBundleNewlyCreatedBanner
) => {
  const [bom, setBom] = useState({
    materials: [],
    status: DEFAULT_BOM_STATUS
  })
  const [products, setProducts] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)
  const [showOutputProductRequiredError, setShowOutputProductRequiredError] = useState(false)
  const [showMaterialRequiredError, setShowMaterialRequiredError] = useState(false)
  const [error, setError] = useState(null)
  const [bomId, setBomId] = useState(initialBomId)
  const [showActiveStatusBanner, setShowActiveStatusBanner] = useState(false)
  const [showBanner, setShowBanner] = useState(false)
  const [showErrorBanner, setShowErrorBanner] = useState(false)
  const [showUpgradeButton, setShowUpgradeButton] = useState(false)
  const { companyId, appBridge, isAppBridgeReady, jobStatus, subscriptionPlan } = useContext(DataUpdateContext)

  const fetchData = useCallback(async () => {
    const promises = []

    setIsLoading(true)
    setError(null)

    if (!bomId || !companyId || !isAppBridgeReady) return

    try {
      promises.push(fetchProducts(companyId, appBridge))
      promises.push(fetchBomsList(companyId, LARGE_REQUEST_LIMIT, 0, appBridge))

      if (bomId !== 'new') promises.push(fetchBomDetails(bomId, appBridge))

      const [productsData, { boms: _boms, total: bomTotal }, bomData] = await Promise.all(promises)
      const usedOutputProducts = _boms.map(bom => bom.output_product_id)

      setUsedProductIds(usedOutputProducts)
      setUsedMaterialIds(usedOutputProducts)

      if (bomId !== 'new') {
        setBom(bomData)
        setForbiddenProductIds(bomData.materials.map((material) => material.product_id))
        setForbiddenMaterialIds(bomData.output_product.product_id)
      }

      setProducts(productsData)

      if (subscriptionPlan === 'free' && bomTotal >= FREE_PLAN_LIMIT && bomId === 'new') {
        setShowUpgradeButton(true)
      }
    } catch (err) {
      setError(err.message)
    } finally {
      setIsLoading(false)
    }
  }, [bomId, companyId, appBridge, isAppBridgeReady, subscriptionPlan, setForbiddenProductIds, setForbiddenMaterialIds, setUsedProductIds, setUsedMaterialIds])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    setIsSaveButtonDisabled(
      !bom.output_product ||
      Boolean(!bom.materials?.length) ||
      bom.materials.some(material => !material.quantity ||
      isNaN(material.quantity) ||
      showUpgradeButton)
    )
    setShowMaterialRequiredError(Boolean(!bom.materials?.length) && bom.bom_id)
  }, [bom, showUpgradeButton])

  useEffect(() => {
    switch (jobStatus) {
      case JOB_STATUS.FAILED:
        setShowBanner(false)
        setShowErrorBanner(true)
        break
      case JOB_STATUS.SUCCEEDED:
        setShowBanner(false)
        setShowErrorBanner(false)
        break
      case JOB_STATUS.IN_PROGRESS:
        setShowBanner(true)
        break
      default:
    }
  }, [jobStatus])

  const prepareBomDataToSave = useCallback(() => {
    return {
      bom_id: bom.bom_id,
      status: bom.status,
      company_id: companyId,
      output_quantity: bom.output_product_quantity || 1,
      output_product_id: bom.output_product_id,
      output_product: {
        inventory_type: bom?.inventory_type || DEFAULT_INVENTORY_TYPE
      },
      materials: bom.materials.map(material => ({
        company_id: companyId,
        material_id: material.material_id,
        product_id: material.product.product_id,
        quantity: +material.quantity,
        product: {
          inventory_type: 'material'
        }
      }))
    }
  }, [bom, companyId])

  const handleDelete = useCallback(async (patchOnly = false) => {
    setError(null)

    try {
      if (patchOnly) {
        await patchBom({ bomId, update: { status: 'deleted' }, appBridge })
      } else {
        await deleteBom(bomId, appBridge)
      }
      return true
    } catch (err) {
      setError(err.message)
      return false
    }
  }, [bomId, appBridge])

  const handleDeleteIfNeeded = useCallback(async (bomDataToSave) => {
    if (bomDataToSave.bom_id !== bomId && bomId !== 'new') {
      await handleDelete()
    }
  }, [handleDelete, bomId])

  const handleSave = useCallback(async () => {
    setIsSaving(true)
    setError(null)

    try {
      const bomDataToSave = prepareBomDataToSave()
      await handleDeleteIfNeeded(bomDataToSave)

      const updatedBom = await updateBom(bomDataToSave, appBridge)
      setBomId(updatedBom.bom_id)

      return updatedBom.bom_id
    } catch (err) {
      setError(err.message)
    } finally {
      setIsSaving(false)
    }
  }, [appBridge, handleDeleteIfNeeded, prepareBomDataToSave, setBomId, setIsSaving])

  const handleQuantityChange = useCallback((materialId, newQuantity) => {
    setBom(prevBom => ({
      ...prevBom,
      materials: prevBom.materials.map(material =>
        material.material_id === materialId ? { ...material, quantity: parseInt(newQuantity, 10) } : material
      )
    }))
  }, [])

  const handleOutputProductSave = useCallback(([newProduct]) => {
    if (!newProduct) return

    const newBomId = objectToId({ outputProductId: newProduct.product_id })

    if (bom.status === 'active') {
      setShowBundleNewlyCreatedBanner(false)
      setShowActiveStatusBanner(true)
    }

    setForbiddenMaterialIds([newProduct.product_id])

    setBom(prevBom => ({
      ...prevBom,
      bom_id: newBomId,
      output_product_id: newProduct.product_id,
      output_product: {
        inventory_type: prevBom?.inventory_type || DEFAULT_INVENTORY_TYPE,
        product_name: newProduct.product_name,
        product_variant_name: newProduct.product_variant_name,
        external_product_id: newProduct.external_product_id,
        external_variant_id: newProduct.external_variant_id
      },
      materials: prevBom.materials.map(material => ({
        ...material,
        company_id: companyId,
        material_id: objectToId({ bomId: newBomId, productId: material.product.product_id })
      }))
    }))
  }, [companyId, setForbiddenMaterialIds, bom.status, setShowBundleNewlyCreatedBanner])

  const handleRemoveMaterial = useCallback((materialId, productId) => {
    setForbiddenProductIds([...forbiddenProductIds].filter((forbiddenProductId) => forbiddenProductId !== productId))

    setBom(prevBom => ({
      ...prevBom,
      materials: prevBom.materials.filter(m => m.material_id !== materialId)
    }))
  }, [forbiddenProductIds, setForbiddenProductIds])

  const handleRemoveOutputProduct = useCallback(() => {
    setForbiddenMaterialIds([])

    setBom(prevBom => ({
      ...prevBom,
      bom_id: undefined,
      output_product_id: undefined,
      output_product: undefined
    }))

    setShowOutputProductRequiredError(true)
  }, [setForbiddenMaterialIds])

  const handleMaterialModalSave = useCallback((newMaterials) => {
    if (!companyId) return
    if (!bom?.materials?.length) setShowOutputProductRequiredError(true)

    setForbiddenProductIds(
      concatArrayWithoutDupes(
        forbiddenProductIds,
        newMaterials.map(newMaterial => newMaterial.product_id)
      )
    )

    setBom(prevBom => ({
      ...prevBom,
      materials: [
        ...prevBom.materials,
        ...newMaterials.map(material => ({
          company_id: companyId,
          material_id: objectToId({ productId: material.product_id, bomId: prevBom.bom_id }),
          product: material,
          quantity: 1,
          product_id: material.product_id
        }))
      ]
    }))
  }, [companyId, bom, forbiddenProductIds, setForbiddenProductIds])

  const handleStatusChange = useCallback((status) => {
    if (status === 'active') {
      setShowBundleNewlyCreatedBanner(false)
      setShowActiveStatusBanner(true)
    } else if (status === 'draft') {
      setShowActiveStatusBanner(false)
    }

    setBom(prevBom => ({
      ...prevBom,
      status
    }))
  }, [setShowBundleNewlyCreatedBanner])

  const handleInventoryTypeChange = useCallback((inventoryType) => {
    setBom(prevBom => ({
      ...prevBom,
      inventory_type: inventoryType[0]
    }))
  }, [])

  return {
    bom,
    products,
    isLoading,
    isSaveButtonDisabled,
    error,
    handleSave,
    handleDelete,
    handleQuantityChange,
    handleOutputProductSave,
    handleRemoveOutputProduct,
    handleRemoveMaterial,
    handleMaterialModalSave,
    handleStatusChange,
    handleInventoryTypeChange,
    showOutputProductRequiredError,
    showMaterialRequiredError,
    showBanner,
    showErrorBanner,
    setShowBanner,
    setShowErrorBanner,
    showUpgradeButton,
    showActiveStatusBanner,
    setShowActiveStatusBanner
  }
}
